import { useDispatch, useSelector } from "react-redux";
import fetchServer from "../controllers/fetchServer";
import {
  setActivities,
  setCancelQuote,
  setTourActivity,
  setTourAvailability,
  setTourBookings,
  setTourGroupQuestions,
  setTourTouristQuestions,
} from "../redux/reducers/tour/tourDataSlice";
import { URLdecode, URLencode } from "../utils/url";
import { useSnackbar } from "notistack";
import {
  setConfirmTourBookingModal,
  setLoadingModal,
  setTourCancelModal,
} from "../redux/reducers/modalSlice";
import { useNavigate } from "react-router-dom";
import useDateFormat from "./useDateFormat";
import { useRef } from "react";
import axios from "axios";
import useLocalStorage from "./useLocalStorage";

export function useTours() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activities, tour, tourTouristQuestions, tourGroupQuestions } =
    useSelector((state) => state.tourData);
  const { filterData } = useSelector((state) => state.tourFilter);
  const { enqueueSnackbar } = useSnackbar();
  const { capitalizeFirstLetter } = useDateFormat();
  const cancelAutocompleteToken = useRef(null);
  const { saveTours } = useLocalStorage();

  const travellersString = () => {
    const params = URLdecode();
    const paxMix = params?.paxMix ? JSON.parse(params?.paxMix) : [];

    if (paxMix && paxMix?.length > 0) {
      let travellers = [];

      paxMix.map((mix, index) => {
        if (mix?.numberOfTravelers > 0) {
          travellers.push(
            `${mix?.numberOfTravelers} ${capitalizeFirstLetter(mix?.ageBand)}`
          );
        }
      });

      if (travellers.length === 0) {
        return "No travellers";
      }

      if (travellers.length === 1) {
        return travellers[0];
      }

      return (
        travellers.slice(0, -1).join(", ") + " and " + travellers.slice(-1)
      );
    }

    let totalAdults = parseInt(params?.adult);
    let totalChildren = parseInt(params?.child);
    let totalInfants = parseInt(params?.infant);

    let parts = [];

    if (totalAdults > 0) {
      parts.push(`${totalAdults} Adult${totalAdults > 1 ? "s" : ""}`);
    }
    if (totalChildren > 0) {
      parts.push(`${totalChildren} Child${totalChildren > 1 ? "ren" : ""}`);
    }
    if (totalInfants > 0) {
      parts.push(`${totalInfants} Infant${totalInfants > 1 ? "s" : ""}`);
    }

    return parts.join(" · ");
  };

  const getDuration = (tour) => {
    let time = tour?.itinerary?.duration?.fixedDurationInMinutes;

    const convertMinutes = (totalMinutes) => {
      let timeString = "";
      const hours = Math.floor(totalMinutes / 60);
      if (hours) {
        timeString += `${hours} hour(s)`;
      }
      const minutes = totalMinutes % 60;
      if (minutes) {
        timeString += ` ${minutes} minute(s)`;
      }
      return timeString;
    };

    if (time !== undefined && time !== null) {
      return convertMinutes(time);
    }

    return "Duration not available";
  };

  const getFullNameFromQuestions = (question) => {
    let name = [];
    let questions = question?.questions || [];

    let firstName = questions?.find((q) => (q.id = "FULL_NAMES_FIRST"));
    let lastName = questions?.find((q) => (q.id = "FULL_NAMES_LAST"));
    if (firstName) name.push(firstName?.answer);
    if (lastName) name.push(lastName?.answer);

    console.log(questions);
    // console.log(lastName);

    return name.join(" ");
  };

  const getIndividualDetails = () => {
    const params = URLdecode();
    let details = [];
    const questions = params?.bookingQuestionAnswers
      ? JSON.parse(params?.bookingQuestionAnswers)
      : [];
    let iQuestions = questions.filter((question) => question?.travelerNum > 0);

    let people = [];

    for (let i = 0; i < iQuestions.length; i++) {
      let personArray = people[iQuestions[i]?.travelerNum - 1]
        ? [...people[iQuestions[i]?.travelerNum - 1]]
        : [];
      personArray.push(iQuestions[i]);
      personArray = personArray?.map((person) => {
        let data = {};
        if (person?.question) {
          data = { ...data, question: capitalizeFirstLetter(person.question) };
        }
        if (person?.answer) {
          let answer = capitalizeFirstLetter(person.answer);
          if (person?.unit) {
            answer = `${answer} (${capitalizeFirstLetter(person?.unit)})`;
          }
          data = { ...data, answer: capitalizeFirstLetter(answer) };
        }

        return data;
      });
      people[iQuestions[i]?.travelerNum - 1] = personArray;
    }

    details = people;

    return details;
  };

  const getGroupDetails = () => {
    const params = URLdecode();
    let details = [];
    const questions = params?.bookingQuestionAnswers
      ? JSON.parse(params?.bookingQuestionAnswers)
      : [];
    let gQuestions = questions.filter((question) => !question?.travelerNum);

    gQuestions = gQuestions?.map((group) => {
      let data = {};
      if (group?.question) {
        data = { ...data, question: capitalizeFirstLetter(group.question) };
      }
      if (group?.answer) {
        let answer = capitalizeFirstLetter(group.answer);
        if (group?.unit) {
          answer = `${answer} (${capitalizeFirstLetter(group?.unit)})`;
        }
        data = { ...data, answer: capitalizeFirstLetter(answer) };
      }

      return data;
    });

    details = gQuestions;

    return details;
  };

  const getAutocomplete = async (q, setLoading) => {
    setLoading && setLoading(true);

    let cancellationMessage = "Operation canceled due to new request.";

    if (cancelAutocompleteToken.current) {
      cancelAutocompleteToken.current.cancel(cancellationMessage);
    }

    cancelAutocompleteToken.current = axios.CancelToken.source();

    let data = await fetchServer({
      method: "GET",
      url: `/product/v1/package/auto-complete?q=${q}`,
      cancelToken: cancelAutocompleteToken.current.token,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return (
              {
                destinations: res.data?.data?.destinations,
                products: res.data?.data?.products,
              } || { destinations: [], products: [] }
            );
          } else {
            return { destinations: [], products: [] };
          }
        }
      })
      .catch((err) => {
        // if (axios.isCancel(err)) {
        // } else {
        //   enqueueSnackbar("Something went wronng. please try again later.", {
        //     variant: "error",
        //   });
        // }

        return [];
      });

    setLoading && setLoading(false);

    return data;
  };

  const getTourPackages = async (setLoading, limit, cancelToken) => {
    const params = URLdecode();

    let data = {
      destinationId: params?.destinationId,
      destinationName: params?.location,
      suppliers: ["Viator", "PackagePro"],
      populate: false,
    };

    if (params?.tag) {
      let tag = parseInt(params?.tag);
      if (tag) {
        data = { ...data, tagId: tag };
      }
    }

    if (limit) {
      data = { ...data, limit: limit };
    }

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/search-by-destination`,
      data,
      // cancelToken: cancelToken?.token,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(setActivities(res.data.data.data));
            saveTours(res.data.data.data);
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        if (axios.isCancel(err)) {
        } else {
          // enqueueSnackbar("Something went wronng. please try again later.", {
          //   variant: "error",
          // });
        }
      });
    setLoading && setLoading(false);
  };

  const getTourActivity = async (setLoading) => {
    const params = URLdecode();
    const productCode = params?.productCode;
    const supplier = params?.supplier;

    if (tour?.productCode === productCode && tour?.bookingQuestions) return;

    setLoading && setLoading(true);

    const data = {
      productCode: productCode,
      supplier: supplier,
    };

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/get-single`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(setTourActivity(res.data.data));
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    setLoading && setLoading(false);
  };

  const getTourReviews = async (setLoading, limit = 20, skip = 0) => {
    const params = URLdecode();
    const productCode = params?.productCode;
    const supplier = params?.supplier;

    setLoading && setLoading(true);

    const data = {
      productCode: productCode,
      supplier: supplier,
      limit,
      skip,
    };

    const response = await fetchServer({
      method: "POST",
      url: `/product/v1/package/product-reviews`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res.data.data || [];
          }
          return [];
        }
      })
      .catch((err) => {
        return [];
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    setLoading && setLoading(false);
    return response || [];
  };

  const getTourAvailability = async (setLoading) => {
    const params = URLdecode();
    const productCode = params?.productCode;
    const supplier = params?.supplier;
    const date = params?.date;

    let paxMix = params?.paxMix ? JSON.parse(params?.paxMix) : [];

    paxMix = paxMix?.map((mix) => {
      return {
        ageBand: mix.ageBand,
        numberOfTravelers: mix.numberOfTravelers,
      };
    });

    paxMix = paxMix.filter((mix) => mix.numberOfTravelers > 0);

    const data = {
      productCode: productCode,
      supplier: supplier,
      travelDate: date,
      paxMix: paxMix,
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/availability`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(setTourAvailability(res.data.data));
          } else {
            dispatch(setTourAvailability(null));
            enqueueSnackbar(res?.data?.message, { variant: "warning" });
          }
        }
      })
      .catch((err) => {
        if (err.message === "Network Error!") {
          enqueueSnackbar("Network Error", { variant: "error" });
        }
      });
    setLoading && setLoading(false);
  };

  const questionsSetup = () => {
    const params = URLdecode();
    const adult = params?.adult;
    const child = params?.child;
    const infant = params?.infant;
    let adults = parseInt(adult) || 0;
    let children = parseInt(child) || 0;
    let infants = parseInt(infant) || 0;
    let paxMix = params?.paxMix ? JSON.parse(params?.paxMix) : [];

    if (!tour) return;

    let bookingQuestions = tour?.bookingQuestions
      ? [...tour?.bookingQuestions]
      : [];

    let gQuestions = bookingQuestions
      .filter((question) => question.group === "PER_BOOKING")
      .map((question) => {
        return {
          allowedAnswers: question?.allowedAnswers,
          id: question?.id,
          required: question?.required,
          maxLength: question?.maxLength,
          label: question?.label,
          units: question?.units,
          answer: "",
        };
      });
    let previousGroup = params?.group ? JSON.parse(params?.group) : null;
    if (previousGroup && previousGroup?.length > 0) {
      dispatch(setTourGroupQuestions(previousGroup));
    } else {
      dispatch(setTourGroupQuestions(gQuestions));
    }
    let iQuestions = bookingQuestions
      .filter((question) => question.group === "PER_TRAVELER")
      .map((question) => {
        return {
          allowedAnswers: question?.allowedAnswers,
          id: question?.id,
          required: question?.required,
          maxLength: question?.maxLength,
          label: question?.label,
          units: question?.units,
          answer: "",
        };
      });

    if (!iQuestions || (iQuestions && iQuestions.length === 0)) return;

    let total = 0;
    if (adults) total += parseInt(adults);
    if (children) total += parseInt(children);
    if (infants) total += parseInt(infants);

    let data = [];
    if (paxMix && paxMix?.length > 0) {
      paxMix.map((people) => {
        let questions = [...iQuestions];
        let ageQuestion = questions.findIndex((ques) => ques.id === "AGEBAND");
        questions[ageQuestion] = {
          ...questions[ageQuestion],
          answer: people?.ageBand,
        };
        if (people?.numberOfTravelers) {
          for (let i = 0; i < people?.numberOfTravelers; i++) {
            data = [
              ...data,
              {
                label: `${capitalizeFirstLetter(people?.ageBand)} ${i + 1}`,
                questions,
              },
            ];
          }
        }
      });
    }

    let previousTourists = params?.people ? JSON.parse(params?.people) : null;
    if (previousTourists && previousTourists.length > 0) {
      return dispatch(setTourTouristQuestions(previousTourists));
    } else {
      dispatch(setTourTouristQuestions(data));
    }
  };

  const groupQuestionTypes = {
    TRANSFER_ARRIVAL_MODE: {
      AIR: [
        "TRANSFER_AIR_ARRIVAL_AIRLINE",
        "TRANSFER_AIR_ARRIVAL_FLIGHT_NO",
        "TRANSFER_ARRIVAL_TIME",
        "TRANSFER_ARRIVAL_DROP_OFF",
        // "PICKUP_POINT",
      ],
      RAIL: [
        "TRANSFER_RAIL_ARRIVAL_LINE",
        "TRANSFER_RAIL_ARRIVAL_STATION",
        "TRANSFER_ARRIVAL_TIME",
        "TRANSFER_ARRIVAL_DROP_OFF",
      ],
      SEA: [
        "TRANSFER_PORT_ARRIVAL_TIME",
        "TRANSFER_PORT_CRUISE_SHIP",
        "TRANSFER_ARRIVAL_DROP_OFF",
        // "PICKUP_POINT",
      ],
      OTHER: ["PICKUP_POINT"],
      types: [
        "TRANSFER_AIR_ARRIVAL_AIRLINE",
        "TRANSFER_AIR_ARRIVAL_FLIGHT_NO",
        "TRANSFER_RAIL_ARRIVAL_LINE",
        "TRANSFER_RAIL_ARRIVAL_STATION",
        "TRANSFER_PORT_ARRIVAL_TIME",
        "TRANSFER_PORT_CRUISE_SHIP",
        "TRANSFER_ARRIVAL_TIME",
        "TRANSFER_ARRIVAL_DROP_OFF",
        // "PICKUP_POINT",
      ],
    },
    TRANSFER_DEPARTURE_MODE: {
      AIR: [
        "TRANSFER_AIR_DEPARTURE_AIRLINE",
        "TRANSFER_AIR_DEPARTURE_FLIGHT_NO",
        "TRANSFER_DEPARTURE_DATE",
        "TRANSFER_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_PICKUP",
      ],
      RAIL: [
        "TRANSFER_RAIL_DEPARTURE_LINE",
        "TRANSFER_RAIL_DEPARTURE_STATION",
        "TRANSFER_DEPARTURE_DATE",
        "TRANSFER_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_PICKUP",
      ],
      SEA: [
        "TRANSFER_PORT_CRUISE_SHIP",
        "TRANSFER_DEPARTURE_DATE",
        "TRANSFER_PORT_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_PICKUP",
      ],
      OTHER: [],
      types: [
        "TRANSFER_AIR_DEPARTURE_AIRLINE",
        "TRANSFER_AIR_DEPARTURE_FLIGHT_NO",
        "TRANSFER_RAIL_DEPARTURE_LINE",
        "TRANSFER_RAIL_DEPARTURE_STATION",
        "TRANSFER_PORT_CRUISE_SHIP",
        "TRANSFER_PORT_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_DATE",
        "TRANSFER_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_PICKUP",
      ],
    },
  };

  const sortGroupQuestions = (questions) => {
    let data = [...questions];

    let pickup = data?.find((q) => q.id === "PICKUP_POINT");
    let withoutPickup = data?.filter((q) => q.id !== "PICKUP_POINT");
    if (pickup) {
      data = [...withoutPickup, pickup];
    }

    let departure = data?.find((q) => q.id === "TRANSFER_DEPARTURE_MODE");
    let withoutDeparture = data?.filter(
      (q) => q.id !== "TRANSFER_DEPARTURE_MODE"
    );
    if (departure) {
      data = [departure, ...withoutDeparture];
    }

    let arrival = data?.find((q) => q.id === "TRANSFER_ARRIVAL_MODE");
    let withoutArrival = data?.filter((q) => q.id !== "TRANSFER_ARRIVAL_MODE");
    if (arrival) {
      data = [arrival, ...withoutArrival];
    }

    return data;
  };

  const isPickUpIncluded = () => {
    const params = URLdecode();
    let productOptions = tour?.productOptions;
    let selectedProductOptionCode = params?.productOptionCode;
    if (productOptions) {
      if (selectedProductOptionCode) {
        let selectedProductOption = productOptions?.find(
          (productOption) =>
            productOption?.productOptionCode === selectedProductOptionCode
        );
        let description = selectedProductOption?.description;
        if (description && description?.includes("Pickup included")) {
          return true;
        }
      }
    }
    return false;
  };

  const groupQuestionsConditioning = () => {
    let travelArrivalMode = null;
    let travelDepartureMode = null;
    let bookingQuestions = tour?.bookingQuestions
      ? [...tour?.bookingQuestions]
      : [];
    let arrivalQuestions = [];
    let departureQuestions = [];
    let pickUpIncluded =
      isPickUpIncluded() ||
      tour?.logistics?.travelerPickup?.pickupOptionType === "PICKUP_EVERYONE" ||
      tour?.logistics?.travelerPickup?.pickupOptionType ===
        "PICKUP_AND_MEET_AT_START_POINT";

    let groupQuestions = bookingQuestions
      .filter((question) => question.group === "PER_BOOKING")
      .map((question) => {
        let savedQuestion = tourGroupQuestions?.find(
          (q) => q?.id === question?.id
        );
        if (savedQuestion) return savedQuestion;
        return {
          allowedAnswers: question?.allowedAnswers,
          id: question?.id,
          required: question?.required,
          maxLength: question?.maxLength,
          label: question?.label,
          units: question?.units,
          answer: "",
          unit: question?.units?.length > 0 ? question?.units[0] : "",
          pickup: question?.pickup,
          departure: question?.departure,
        };
      });

    travelArrivalMode = groupQuestions.find(
      (q) => q.id === "TRANSFER_ARRIVAL_MODE"
    );

    travelDepartureMode = groupQuestions.find(
      (q) => q.id === "TRANSFER_DEPARTURE_MODE"
    );

    if (travelArrivalMode) {
      arrivalQuestions = groupQuestionTypes.TRANSFER_ARRIVAL_MODE.types.filter(
        (type, index) => {
          let isAvailable = groupQuestions.find((q) => q.id === type);
          if (isAvailable) return true;
          return false;
        }
      );
    }
    if (travelDepartureMode) {
      departureQuestions =
        groupQuestionTypes.TRANSFER_DEPARTURE_MODE.types.filter(
          (type, index) => {
            let isAvailable = groupQuestions.find((q) => q.id === type);
            if (isAvailable) return true;
            return false;
          }
        );
    }

    if (arrivalQuestions.length > 0 && !travelArrivalMode?.answer) {
      groupQuestions = groupQuestions.filter((q) => {
        let isInArrivalQuestions = arrivalQuestions.find(
          (ques) => q.id === ques
        );
        if (isInArrivalQuestions) return false;
        return true;
      });
    } else if (arrivalQuestions.length > 0 && travelArrivalMode?.answer) {
      const filterArrivalQuestions = (type) => {
        groupQuestions = groupQuestions.filter((question) => {
          let selectedQuestions =
            groupQuestionTypes.TRANSFER_ARRIVAL_MODE[type];
          let notSelectedQuestions = arrivalQuestions.filter((ques) => {
            let isAvailable = selectedQuestions.find((q) => q === ques);
            if (isAvailable) return false;
            return true;
          });
          let isAvailable = notSelectedQuestions.find((q) => q === question.id);
          if (isAvailable) return false;
          return true;
        });
        if (pickUpIncluded) {
          groupQuestions = groupQuestions?.map((question) => {
            let selectedQuestions =
              groupQuestionTypes.TRANSFER_ARRIVAL_MODE[type];
            let isAvailable = selectedQuestions.find((q) => q === question.id);
            if (isAvailable) {
              return {
                ...question,
                pickup: true,
              };
            } else {
              return question;
            }
          });
        }
      };

      switch (travelArrivalMode?.answer) {
        case "AIR":
          filterArrivalQuestions("AIR");
          break;
        case "RAIL":
          filterArrivalQuestions("RAIL");
          break;
        case "SEA":
          filterArrivalQuestions("SEA");
          break;
        case "OTHER":
          filterArrivalQuestions("OTHER");
          break;
        default:
          break;
      }
    }

    if (departureQuestions.length > 0 && !travelDepartureMode?.answer) {
      groupQuestions = groupQuestions.filter((q) => {
        let isInDepartureQuestions = departureQuestions.find(
          (ques) => q.id === ques
        );
        if (isInDepartureQuestions) return false;
        return true;
      });
    } else if (departureQuestions.length > 0 && travelDepartureMode?.answer) {
      const filterDepartureQuestions = (type) => {
        groupQuestions = groupQuestions.filter((question) => {
          let selectedQuestions =
            groupQuestionTypes.TRANSFER_DEPARTURE_MODE[type];
          let notSelectedQuestions = departureQuestions.filter((ques) => {
            let isAvailable = selectedQuestions.find((q) => q === ques);
            if (isAvailable) return false;
            return true;
          });
          let isAvailable = notSelectedQuestions.find((q) => q === question.id);
          if (isAvailable) return false;
          return true;
        });
        if (pickUpIncluded) {
          groupQuestions = groupQuestions?.map((question) => {
            let selectedQuestions =
              groupQuestionTypes.TRANSFER_DEPARTURE_MODE[type];
            let isAvailable = selectedQuestions.find((q) => q === question.id);
            if (isAvailable) {
              return {
                ...question,
                departure: true,
              };
            } else {
              return question;
            }
          });
        }
      };
      switch (travelDepartureMode?.answer) {
        case "AIR":
          filterDepartureQuestions("AIR");
          break;
        case "RAIL":
          filterDepartureQuestions("RAIL");
          break;
        case "SEA":
          filterDepartureQuestions("SEA");
          break;
        case "OTHER":
          filterDepartureQuestions("OTHER");
          break;
        default:
          break;
      }
    }
    groupQuestions = groupQuestions?.map((q) => {
      if (q?.id === travelArrivalMode?.id || q?.id === "PICKUP_POINT") {
        return { ...q, pickup: pickUpIncluded };
      } else if (q?.id === travelDepartureMode?.id) {
        return { ...q, departure: true };
      } else return q;
    });

    groupQuestions = sortGroupQuestions(groupQuestions);
    dispatch(setTourGroupQuestions(groupQuestions));
  };

  const handleAnswerChange = (data) => {
    if (data.group) {
      let questions = [...tourGroupQuestions];
      let question = questions[data?.questionIndex];
      questions[data?.questionIndex] = data?.unit
        ? { ...question, unit: data?.value }
        : { ...question, answer: data?.value };
      dispatch(setTourGroupQuestions(questions));
      // setTimeout(() => {
      //   groupQuestionsConditioning();
      // }, 1000);
    } else {
      let people = [...tourTouristQuestions];
      let person = people[data?.travellerIndex];
      let questions = [...person?.questions];
      let question = questions[data?.questionIndex];
      questions[data?.questionIndex] = data?.unit
        ? { ...question, unit: data?.value }
        : { ...question, answer: data?.value };
      people[data?.travellerIndex] = { ...person, questions: questions };
      // setTourists(people);
      dispatch(setTourTouristQuestions(people));
    }
  };

  const bookTour = async (setLoading) => {
    const params = URLdecode();

    let paxMix = params?.paxMix ? JSON.parse(params?.paxMix) : [];
    let languageGuide = params?.languageGuide
      ? JSON.parse(params?.languageGuide)
      : null;

    paxMix = paxMix?.map((mix) => {
      return {
        ageBand: mix.ageBand,
        numberOfTravelers: mix.numberOfTravelers,
      };
    });

    paxMix = paxMix.filter((mix) => mix.numberOfTravelers > 0);

    let people = params?.people ? JSON.parse(params?.people) : [];
    let group = params?.group ? JSON.parse(params?.group) : [];

    let bookingQuestionAnswers = [];

    for (let i = 0; i < people.length; i++) {
      let questions = people[i]?.questions?.map((question) => {
        let item = {
          question: question?.id,
          answer: question?.answer,
          travelerNum: i + 1,
        };
        if (question?.unit) {
          item = { ...item, unit: question.unit };
        }
        return item;
      });
      bookingQuestionAnswers = [...bookingQuestionAnswers, ...questions];
    }

    let groupQuestions = group?.map((question) => {
      let item = {
        question: question?.id,
        answer: question?.answer,
      };
      if (question?.unit) {
        item = { ...item, unit: question.unit };
      }
      return item;
    });
    bookingQuestionAnswers = [...bookingQuestionAnswers, ...groupQuestions];

    let bookingItem = {
      productCode: params?.productCode,
      productOptionCode: params?.productOptionCode || undefined,
      travelDate: params?.date,
      startTime: params?.startTime,
      paxMix: paxMix,
      bookingQuestionAnswers: bookingQuestionAnswers,
    };

    if (languageGuide) {
      bookingItem = {
        ...bookingItem,
        languageGuide,
      };
    }

    const data = {
      items: [bookingItem],
      supplier: params?.supplier,
      contactEmail: params?.email,
      showPrice: true,
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/hold-booking`,
      data,
    })
      .then((res) => {
        dispatch(setConfirmTourBookingModal(false));
        if (res) {
          if (res.status === 200) {
            enqueueSnackbar("Booking successfull", { variant: "success" });

            if (params?.payOption === "now") {
              let parameters = {
                ...params,
                bookingId: res?.data?.data?.bookedPackage?._id,
                bookId: res?.data?.data?.booking?.bookingId,
                bookingDate: res?.data?.data?.booking?.createdAt,
                price: res?.data?.data?.bookedPackage?.basePrice,
                bookingQuestionAnswers: JSON.stringify(
                  res?.data?.data?.params?.items[0]?.bookingQuestionAnswers
                ),
              };
              navigate(`/tour/payment?${URLencode(parameters)}`);
            } else {
              navigate("/order");
            }
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        dispatch(setConfirmTourBookingModal(false));
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  function getBaseURL() {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    const baseURL = `${protocol}//${hostname}${port ? ":" + port : ""}`;
    return baseURL;
  }

  const initializePayment = async (setLoading) => {
    const params = URLdecode();

    const data = {
      packageBookingId: params?.bookingId,
      paymentMode: "Card",
      callbackUrl: `${getBaseURL()}/tour/confirmation?${URLencode(params)}`,
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/payment/v1/payment/package`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            if (res?.data?.data?.data?.authorization_url) {
              window.location.href = res?.data?.data?.data?.authorization_url;
            }
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wronng. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const getTourBookings = async (setLoading) => {
    setLoading && setLoading(true);

    const response = await fetchServer({
      method: "GET",
      url: `/product/v1/package`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res.data.data;
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    setLoading && setLoading(false);
    return response || [];
  };

  const getParameters = (booking) => {
    const image = () => {
      let img = "";
      let extraDetails = booking?.extraDetails;
      if (extraDetails) {
        let productInfo = extraDetails?.productInfo;
        if (productInfo) {
          let images = productInfo?.images;
          if (images && images.length > 0) {
            let coverImage = images?.find((item) => item?.isCover) || images[0];
            if (coverImage) {
              let variants = coverImage?.variants;
              if (variants && variants?.length > 1) {
                let selectedImage = variants[12] || variants[0];
                if (selectedImage) {
                  img = selectedImage?.url || "";
                }
              }
            }
          }
        }
      }

      return img;
    };
    const destination = () => {
      let destinationName = "";
      let extraDetails = booking?.extraDetails;
      if (extraDetails) {
        let productInfo = extraDetails?.productInfo;
        if (productInfo) {
          let destinations = productInfo?.destinations;
          if (destinations && destinations.length > 0) {
            let dest = destinations[0];
            if (dest) {
              destinationName = dest?.destinationName || "";
            }
          }
        }
      }

      return destinationName;
    };

    const people = () => {
      let traverlers = {
        adult: 0,
        child: 0,
        infant: 0,
      };
      let items = booking?.holdBookingRes?.items;
      if (items) {
        let item = items[0];
        let lineItems = item?.lineItems;
        let adult = lineItems?.find(
          (person) => person.ageBand === "ADULT"
        )?.numberOfTravelers;
        let child = lineItems?.find(
          (person) => person.ageBand === "CHILD"
        )?.numberOfTravelers;
        let infant = lineItems?.find(
          (person) => person.ageBand === "INFANT"
        )?.numberOfTravelers;
        if (adult) {
          traverlers = { ...traverlers, adult: adult };
        }
        if (child) {
          traverlers = { ...traverlers, child: child };
        }
        if (infant) {
          traverlers = { ...traverlers, infant: infant };
        }
      }

      return traverlers;
    };

    let bookingQuestions = booking?.bookingQuestionAnswers;

    bookingQuestions = bookingQuestions?.map((question) => {
      let updatedQuestion = question;
      if (updatedQuestion.unit === "LOCATION_REFERENCE") {
        let locations = booking?.extraDetails?.productInfo?.locations;
        if (updatedQuestion.answer === "MEET_AT_DEPARTURE_POINT") {
          let ref =
            updatedQuestion?.extraDetails?.productInfo?.logistics?.start?.at(0)
              ?.location?.ref;
          let location = locations?.find((loc) => loc.reference === ref);
          if (location) {
            updatedQuestion = { ...updatedQuestion, answer: location.name };
          }
        } else {
          if (locations) {
            let location = locations?.find(
              (loc) => loc.reference === updatedQuestion.answer
            );
            if (location) {
              updatedQuestion = { ...updatedQuestion, answer: location.name };
            }
          }
        }
      }
      return updatedQuestion;
    });

    let parameters = {
      bookingQuestionAnswers: JSON.stringify(bookingQuestions),
      status: booking?.status,
      image: image(),
      date: booking?.travelDate,
      tourDuration: getDuration(booking?.extraDetails?.productInfo),
      cancellationPolicy: JSON.stringify(
        booking?.extraDetails?.productInfo?.cancellationPolicy
      ),
      title: booking?.extraDetails?.productInfo?.title,
      location: destination(),
      bookingDate: booking?.createdAt,
      bookingId: booking?._id,
      bookId: booking?.itemRef,
      supplier: booking?.supplier,
      startTime: booking?.startTime,
      voucher: JSON.stringify(booking?.voucherInfo),
    };
    if (people().adult) {
      parameters = { ...parameters, adult: people().adult };
    }
    if (people().child) {
      parameters = { ...parameters, child: people().child };
    }
    if (people().infant) {
      parameters = { ...parameters, infant: people().infant };
    }

    return parameters;
  };

  const getBooking = async () => {
    const params = URLdecode();

    dispatch(setLoadingModal(true));

    await fetchServer({
      method: "GET",
      url: `/product/v1/package/${params?.bookingId}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;

            let parameters = getParameters(booking);

            let url = `/tour/confirmation?${URLencode(parameters)}`;
            window.location.replace(url);
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wronng. please try again later.",
              {
                variant: "error",
              }
            );
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
      });
    dispatch(setLoadingModal(false));
  };

  const confirmPackage = async () => {
    const params = URLdecode();

    dispatch(setLoadingModal(true));

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/confirm-booking/${params?.bookingId}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;

            let parameters = getParameters(booking);

            let url = `/tour/confirmation?${URLencode(parameters)}`;
            window.location.replace(url);
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                res?.data?.error ||
                "Something went wronng. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
      })
      .finally(() => {
        // dispatch(setTourCancelModal(false));
        dispatch(setLoadingModal(false));
      });
  };

  const cancelAndQuote = async () => {
    const params = URLdecode();

    dispatch(setLoadingModal(true));
    const data = {
      supplier: params?.supplier,
      bookingId: params?.bookingId,
    };

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/cancel-quote`,
      data,
    })
      .then(async (res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;
            let cancelQuote = booking?.cancelQuote;
            dispatch(setCancelQuote(booking));
            dispatch(setTourCancelModal(true));
            if (cancelQuote?.status === "CANCELLABLE") {
              // await cancelAndRefund();
            } else {
              enqueueSnackbar("This tour is not cancellable!", {
                variant: "warning",
              });
            }
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
      });
    dispatch(setLoadingModal(false));
  };

  const cancelAndRefund = async (reason) => {
    const params = URLdecode();

    dispatch(setLoadingModal(true));
    const data = {
      supplier: params?.supplier,
      bookingId: params?.bookingId,
      cancelReason:
        reason || "Customer_Service.Unexpected_medical_circumstances",
    };

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/cancel-confirmed-booking`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;

            let parameters = getParameters(booking);

            let url = `/tour/confirmation?${URLencode(parameters)}`;
            window.location.replace(url);
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wronng. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
      })
      .finally(() => {
        dispatch(setTourCancelModal(false));
      });
    dispatch(setLoadingModal(false));
  };

  return {
    getDuration,
    travellersString,
    getFullNameFromQuestions,
    getIndividualDetails,
    getGroupDetails,
    getAutocomplete,
    getTourPackages,
    getTourActivity,
    getTourReviews,
    getTourAvailability,
    questionsSetup,
    groupQuestionsConditioning,
    handleAnswerChange,
    bookTour,
    initializePayment,
    getTourBookings,
    getBooking,
    confirmPackage,
    cancelAndRefund,
    cancelAndQuote,
  };
}

// const groupQuestionsConditioning = (questions, setQuestions) => {
//   let travelArrivalMode = null;
//   let travelDepartureMode = null;
//   let bookingQuestions = tour?.bookingQuestions
//     ? [...tour?.bookingQuestions]
//     : [];
//   let arrivalQuestions = [];
//   let departureQuestions = [];

//   let groupQuestions = bookingQuestions
//     .filter((question) => question.group === "PER_BOOKING")
//     .map((question) => {
//       let savedQuestion = questions?.find((q) => q?.id === question?.id);
//       if (savedQuestion) return savedQuestion;
//       return {
//         allowedAnswers: question?.allowedAnswers,
//         id: question?.id,
//         required: question?.required,
//         maxLength: question?.maxLength,
//         label: question?.label,
//         units: question?.units,
//         answer: "",
//       };
//     });

//   travelArrivalMode = groupQuestions.find(
//     (q) => q.id === "TRANSFER_ARRIVAL_MODE"
//   );

//   travelDepartureMode = groupQuestions.find(
//     (q) => q.id === "TRANSFER_DEPARTURE_MODE"
//   );

//   if (travelArrivalMode) {
//     arrivalQuestions = groupQuestionTypes.TRANSFER_ARRIVAL_MODE.types.filter(
//       (type, index) => {
//         let isAvailable = groupQuestions.find((q) => q.id === type);
//         if (isAvailable) return true;
//         return false;
//       }
//     );
//   }
//   if (travelDepartureMode) {
//     departureQuestions =
//       groupQuestionTypes.TRANSFER_DEPARTURE_MODE.types.filter(
//         (type, index) => {
//           let isAvailable = groupQuestions.find((q) => q.id === type);
//           if (isAvailable) return true;
//           return false;
//         }
//       );
//   }

//   if (arrivalQuestions.length > 0 && !travelArrivalMode?.answer) {
//     groupQuestions = groupQuestions.filter((q) => {
//       let isInArrivalQuestions = arrivalQuestions.find(
//         (ques) => q.id === ques
//       );
//       if (isInArrivalQuestions) return false;
//       return true;
//     });
//   } else if (arrivalQuestions.length > 0 && travelArrivalMode?.answer) {
//     switch (travelArrivalMode?.answer) {
//       case "AIR":
//         groupQuestions = groupQuestions.filter((question) => {
//           let airQuestions = groupQuestionTypes.TRANSFER_ARRIVAL_MODE.AIR;
//           let notAirQuestions = arrivalQuestions.filter((ques) => {
//             let isAvailable = airQuestions.find((q) => q === ques);
//             if (isAvailable) return false;
//             return true;
//           });
//           let isAvailable = notAirQuestions.find((q) => q === question.id);
//           if (isAvailable) return false;
//           return true;
//         });
//         break;
//       case "RAIL":
//         groupQuestions = groupQuestions.filter((question) => {
//           let railQuestions = groupQuestionTypes.TRANSFER_ARRIVAL_MODE.RAIL;
//           let notRailQuestions = arrivalQuestions.filter((ques) => {
//             let isAvailable = railQuestions.find((q) => q === ques);
//             if (isAvailable) return false;
//             return true;
//           });
//           let isAvailable = notRailQuestions.find((q) => q === question.id);
//           if (isAvailable) return false;
//           return true;
//         });
//         break;
//       case "SEA":
//         groupQuestions = groupQuestions.filter((question) => {
//           let seaQuestions = groupQuestionTypes.TRANSFER_ARRIVAL_MODE.SEA;
//           let notSeaQuestions = arrivalQuestions.filter((ques) => {
//             let isAvailable = seaQuestions.find((q) => q === ques);
//             if (isAvailable) return false;
//             return true;
//           });
//           let isAvailable = notSeaQuestions.find((q) => q === question.id);
//           if (isAvailable) return false;
//           return true;
//         });
//         break;
//       case "OTHER":
//         groupQuestions = groupQuestions.filter((question) => {
//           let otherQuestions = groupQuestionTypes.TRANSFER_ARRIVAL_MODE.OTHER;
//           let notOtherQuestions = arrivalQuestions.filter((ques) => {
//             let isAvailable = otherQuestions.find((q) => q === ques);
//             if (isAvailable) return false;
//             return true;
//           });
//           let isAvailable = notOtherQuestions.find((q) => q === question.id);
//           if (isAvailable) return false;
//           return true;
//         });
//         break;
//       default:
//         break;
//     }
//   }

//   if (departureQuestions.length > 0 && !travelDepartureMode?.answer) {
//     groupQuestions = groupQuestions.filter((q) => {
//       let isInDepartureQuestions = departureQuestions.find(
//         (ques) => q.id === ques
//       );
//       if (isInDepartureQuestions) return false;
//       return true;
//     });
//   } else if (departureQuestions.length > 0 && travelDepartureMode?.answer) {
//     switch (travelDepartureMode?.answer) {
//       case "AIR":
//         groupQuestions = groupQuestions.filter((question) => {
//           let airQuestions = groupQuestionTypes.TRANSFER_DEPARTURE_MODE.AIR;
//           let notAirQuestions = departureQuestions.filter((ques) => {
//             let isAvailable = airQuestions.find((q) => q === ques);
//             if (isAvailable) return false;
//             return true;
//           });
//           let isAvailable = notAirQuestions.find((q) => q === question.id);
//           if (isAvailable) return false;
//           return true;
//         });
//         break;
//       case "RAIL":
//         groupQuestions = groupQuestions.filter((question) => {
//           let railQuestions = groupQuestionTypes.TRANSFER_DEPARTURE_MODE.RAIL;
//           let notRailQuestions = departureQuestions.filter((ques) => {
//             let isAvailable = railQuestions.find((q) => q === ques);
//             if (isAvailable) return false;
//             return true;
//           });
//           let isAvailable = notRailQuestions.find((q) => q === question.id);
//           if (isAvailable) return false;
//           return true;
//         });
//         break;
//       case "SEA":
//         groupQuestions = groupQuestions.filter((question) => {
//           let seaQuestions = groupQuestionTypes.TRANSFER_DEPARTURE_MODE.SEA;
//           let notSeaQuestions = departureQuestions.filter((ques) => {
//             let isAvailable = seaQuestions.find((q) => q === ques);
//             if (isAvailable) return false;
//             return true;
//           });
//           let isAvailable = notSeaQuestions.find((q) => q === question.id);
//           if (isAvailable) return false;
//           return true;
//         });
//         break;
//       case "OTHER":
//         groupQuestions = groupQuestions.filter((question) => {
//           let otherQuestions =
//             groupQuestionTypes.TRANSFER_DEPARTURE_MODE.OTHER;
//           let notOtherQuestions = departureQuestions.filter((ques) => {
//             let isAvailable = otherQuestions.find((q) => q === ques);
//             if (isAvailable) return false;
//             return true;
//           });
//           let isAvailable = notOtherQuestions.find((q) => q === question.id);
//           if (isAvailable) return false;
//           return true;
//         });
//         break;
//       default:
//         break;
//     }
//   }
//   groupQuestions = sortGroupQuestions(groupQuestions);
//   setQuestions(groupQuestions);
// };
