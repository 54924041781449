import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTours } from "../../hooks/useTours";
import { URLdecode, URLencode } from "../../utils/url";

function TourDestinationSearch({
  placeholder,
  selected,
  label,
  onChange,
  noLabel,
}) {
  const { getAutocomplete, getDuration } = useTours();
  const [value, setValue] = useState(selected);
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [destinations, setDestinations] = useState([]);

  const loadDestinations = async () => {
    if (!value) return;
    let data = await getAutocomplete(value, setLoading);
    let tempData = [];
    if (data?.destinations) {
      data?.destinations.map((loc) => {
        tempData.push({
          type: "location",
          ...loc,
        });
        let tags = loc?.tags;
        if (tags) {
          tags?.slice(0, 3)?.map((tag) => {
            tempData.push({
              type: "category",
              ...loc,
              tag: tag,
            });
          });
        }
      });
    }
    if (data?.products) {
      data?.products.map((loc) => {
        tempData.push({
          type: "product",
          ...loc,
        });
      });
    }

    setDestinations(tempData);
  };

  useEffect(() => {
    if (value) {
      loadDestinations();
    }
  }, [value]);

  const handleChange = (val) => {
    // console.log("Value: ", val?.destinationName);
    setValue(val?.destinationName);
    onChange(val?.dbid, val?.destinationName, val?.tag?.tagId);
  };

  const region = (destination) => {
    let name = "";
    if (destination?.lookUpData) {
      let data = destination?.lookUpData;
      data = data?.map((item) => {
        if (item?.destinationName) {
          return item.destinationName;
        }
      });

      let joined_string = data.join(", ");
      return joined_string;
    }

    return name;
  };

  const coverImage = (tour) => {
    let cover = "";
    if (tour) {
      if (tour?.images) {
        tour?.images?.map((image, index) => {
          if (image?.isCover === true) {
            cover = image?.variants[12]?.url;
          }
        });
        if (!cover) {
          cover = tour?.images[0]?.variants[12]?.url;
        }
      }
    }
    return cover;
  };

  const url = (tour) => {
    const params = URLdecode();

    const parameters = {
      ...params,
      productCode: tour?.productCode,
      supplier: tour?.package_supplier || "Viator",
      tourDuration: getDuration(tour),
    };

    let text = `/tour/singleTour?${URLencode(parameters)}`;

    return text;
  };

  return (
    <div className="flex flex-1 flex-col relative h-fit z-20 w-full">
      {!noLabel && (
        <span className={Classname.formInputLabel}>
          {label || "Enter location"}
        </span>
      )}
      <div className={Classname.inputContainer}>
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder || "e.g Nigeria"}
          className="flex flex-1"
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setFocused(false);
            }, 300);
          }}
        />
        {loading && <div className="loaderSearch"></div>}
      </div>
      {focused && destinations && destinations.length > 0 && (
        <div
          className={`bg-white z-30 absolute w-full shadow-lg ${
            noLabel ? "top-16" : "top-28"
          } max-h-[300px] flex flex-col overflow-scroll scroll-hide py-4 rounded-lg z-50`}
        >
          {destinations.map((destination, index) =>
            destination.type === "product" ? (
              <a
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                href={url(destination)}
                className="cursor-pointer w-full flex px-6 hover:bg-gray-50 z-20"
              >
                <div className=" w-full border-b flex gap-3 py-3 items-center">
                  <img
                    src={coverImage(destination)}
                    alt=""
                    className="w-16 object-contain"
                  />
                  <div className="flex flex-1 flex-col">
                    <span className="text-base">{destination?.title}</span>
                  </div>
                </div>
              </a>
            ) : (
              <div
                key={index}
                onClick={() => handleChange(destination)}
                className="cursor-pointer w-full flex px-6 hover:bg-gray-50 z-20"
              >
                <div className=" w-full border-b flex gap-3 py-3 ">
                  {destination?.type === "category" ? (
                    <img
                      src="/IconTagGreen.svg"
                      alt=""
                      className={Classname.icon}
                    />
                  ) : (
                    <img
                      src="/IconLocationGreen.svg"
                      alt=""
                      className={Classname.icon}
                    />
                  )}
                  <div className="flex flex-1 flex-col">
                    <span className="text-base">
                      {destination?.destinationName}{" "}
                      {destination?.type === "category"
                        ? destination?.tag?.tagName
                        : ""}
                    </span>
                    {region(destination) && (
                      <span className="text-sm ">{region(destination)}</span>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}

export default TourDestinationSearch;

const Classname = {
  formTitle: "font-black text-primaryText text-lg mb-6",
  formInputLabel: "flex font-bold text-base mb-2 mt-6 text-gray-500",
  inputContainer:
    "w-full h-12 rounded-md px-4 border border-gray-300 flex items-center",
  icon: "w-5 object-contain",
};
