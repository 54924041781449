import React from 'react'
import Modal1 from '../DIsplay/Modal/Modal1'
import Button1 from '../form/Button1'
import { useParams } from 'react-router-dom'

export default function GuestGuard({open,setOpen,continueAsGuest}) {
    const {id} = useParams();
  return (
    <Modal1 open={open} setOpen={setOpen} containerClass={'bg-primary/60  backdrop-blur-sm'}>
        <div className='card p-10 flex flex-col gap-4'>
            <h5 className='py-3'>Login / Signup to continue with your booking</h5>
            <Button1 to={`/?view=login&redirect=order/new/flight/book/details/${id}`}>Login</Button1>
            <Button1 to={`/?view=register&redirect=order/new/flight/book/details/${id}`} variant='outlined'>Register</Button1>
            {/* <Button1 onClick={() => continueAsGuest && continueAsGuest()} variant='text'>Contine as guest</Button1> */}
        </div>
    </Modal1>
  )
}
