import { useDispatch, useSelector } from "react-redux";
import fetchServer from "../controllers/fetchServer";
import {
  setHotelAvailability,
  setHotelBookings,
  setHotels,
} from "../redux/reducers/tour/hotelDataSlice";
import { URLdecode, URLencode } from "../utils/url";
import { setAdultsNo, setChildrenNo } from "../redux/reducers/tour/dataSlice";
import { useSnackbar } from "notistack";
import {
  setConfirmHotelBookingModal,
  setLoadingModal,
} from "../redux/reducers/modalSlice";
import { useNavigate } from "react-router-dom";
import useDateFormat from "./useDateFormat";

export function useHotels() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { rooms: totalRooms } = useSelector((state) => state.data);
  const { hotelAvailability } = useSelector((state) => state.hotelData);
  const { calculateAge } = useDateFormat();

  const travellersString = (local) => {
    const params = URLdecode();
    let rooms = totalRooms;

    if (params?.rooms && !local) {
      rooms = JSON.parse(params?.rooms);
    }

    let totalAdults = 0;
    let totalChildren = 0;
    let totalInfants = 0;

    rooms.forEach((room) => {
      totalAdults += room.adults;
      totalChildren += room.children?.length;
      totalInfants += room.infants;
    });

    let parts = [];

    if (totalAdults > 0) {
      parts.push(`${totalAdults} Adult${totalAdults > 1 ? "s" : ""}`);
    }
    if (totalChildren > 0) {
      parts.push(`${totalChildren} Child${totalChildren > 1 ? "ren" : ""}`);
    }
    if (totalInfants > 0) {
      parts.push(`${totalInfants} Infant${totalInfants > 1 ? "s" : ""}`);
    }

    parts.push(`${rooms.length} Room${rooms.length > 1 ? "s" : ""}`);
    // Join the parts with " · " separator
    return parts.join(" · ");
  };

  const getHotels = async (setLoading) => {
    const params = URLdecode();
    const start_date = params?.start_date;
    const end_date = params?.end_date;
    const lng = params?.lng;
    const lat = params?.lat;

    let roomsArray = JSON.parse(params?.rooms);

    roomsArray = roomsArray?.map((item) => {
      return {
        ...item,
        units: 1,
      };
    });

    if (parseInt(params?.adult)) {
      dispatch(setAdultsNo(parseInt(params?.adult)));
    }
    if (parseInt(params?.children)) {
      dispatch(setChildrenNo(parseInt(params?.children)));
    }

    const data = {
      latitude: parseFloat(lat),
      longitude: parseFloat(lng),
      start_date,
      end_date,
      range: 200,
      // suppliers: ["HotelBeds"],
      // suppliers: ["Sabre"],
      // suppliers: ["AmadeusSoap", "Sabre", "HotelBeds", "HotelX"],
      suppliers: ["StayX1", "StayX2", "StayX3", "StayX4"],
      rooms: roomsArray,
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/search`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(setHotels(res.data.data.data));
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    setLoading && setLoading(false);
  };

  const getHotelAvailability = async (setLoading) => {
    const params = URLdecode();
    dispatch(setHotelAvailability(null));

    let roomsArray = JSON.parse(params?.rooms);

    roomsArray = roomsArray?.map((item) => {
      return {
        ...item,
        units: 1,
      };
    });

    let data = {
      start_date: params?.start_date,
      end_date: params?.end_date,
      rooms: roomsArray,
      code: params?.code,
      codeContext: params?.codeContext,
      name: params?.name,
      cityCode: params?.cityCode,
      chainCode: params?.chainCode,
      chainName: params?.chainName,
      areaId: params?.areaId,
      categoryCode: params?.categoryCode,
      address: params?.address,
      city: params?.city,
      postalCode: params?.postalCode,
      countryCode: params?.countryCode,
      units: params?.units,
      nights: params?.nights,
      supplier: params?.supplier,
    };

    if (params?.availabilities) {
      data = { ...data, availabilities: JSON.parse(params?.availabilities) };
    }

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/availability`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(setHotelAvailability(res.data.data));
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });
    setLoading && setLoading(false);
  };

  const checkRoom = async ({
    setLoading,
    hotelCode,
    roomCode,
    bookingCode,
    supplier,
  }) => {
    setLoading && setLoading(true);

    const data = await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/check-rate`,
      data: {
        hotelCode,
        roomCode,
        bookingCode,
        supplier,
      },
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res?.data?.data;
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong while checking room availability. please try again later.",
              {
                variant: "error",
              }
            );
            return null;
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          "Something went wrong while checking room availability. please try again later.",
          {
            variant: "error",
          }
        );
      });
    setLoading && setLoading(false);

    return data;
  };

  const checkRooms = async ({
    setLoading,
    hotelCode,
    roomCodes,
    bookingCodes,
    supplier,
  }) => {
    setLoading && setLoading(true);

    const data = await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/check-rate`,
      data: {
        hotelCode,
        roomCodes,
        bookingCodes,
        supplier,
      },
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res?.data?.data;
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong while checking room availability. please try again later.",
              {
                variant: "error",
              }
            );
            return null;
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          "Something went wrong while checking room availability. please try again later.",
          {
            variant: "error",
          }
        );
      });
    setLoading && setLoading(false);

    return data;
  };

  const recheckRooms = async () => {
    if (hotelAvailability) {
      if (hotelAvailability?.supplier !== "HotelBeds") return null;
      let rooms = hotelAvailability?.rooms;
      if (rooms) {
        // const roomsToRecheck = rooms?.filter(
        //   (room) => room.availabilityStatus === "RECHECK"
        // );
        const roomsToRecheck = rooms;

        let payloads = [];

        let roomCodes = [];
        let bookingCodes = [];

        for (let i = 0; i < roomsToRecheck.length; i++) {
          roomCodes.push(roomsToRecheck[i].code);
          bookingCodes.push(roomsToRecheck[i].bookingCode);
        }

        const chunkSize = 10;
        for (let i = 0; i < roomCodes.length; i += chunkSize) {
          payloads.push({
            roomCodes: roomCodes.slice(i, i + chunkSize),
            bookingCodes: bookingCodes.slice(i, i + chunkSize),
            hotelCode: hotelAvailability.code,
            supplier: hotelAvailability.supplier,
          });
        }

        const responses = await Promise.all(
          payloads.map((payload) => checkRooms(payload))
        );
        let recheckedRooms = [];

        for (let i = 0; i < responses.length; i++) {
          if (responses[i]) {
            recheckedRooms = [...recheckedRooms, ...responses[i]];
          }
        }
        let availableRooms = hotelAvailability.rooms
          ? [...hotelAvailability.rooms]
          : [];
        availableRooms = availableRooms.map((room) => {
          let tempRoom = recheckedRooms.find(
            (rm) => rm.bookingCode === room.bookingCode
          );
          if (tempRoom) {
            return tempRoom;
          } else return room;
        });

        let tempHotelAvailability = {
          ...hotelAvailability,
          rooms: availableRooms,
        };
        return tempHotelAvailability || null;
      }
    }
  };

  const bookRooms = async (setLoading) => {
    const params = URLdecode();

    let roomsArray = JSON.parse(params?.rooms);

    roomsArray = roomsArray?.map((item) => {
      return {
        ...item,
        units: 1,
      };
    });

    const data = {
      code: params?.code,
      name: params?.name,
      cityCode: params?.cityCode,
      chainCode: params?.chainCode,
      chainName: params?.chainName,
      areaId: params?.areaId,
      categoryCode: params?.categoryCode,
      address: params?.address,
      city: params?.city,
      postalCode: params?.postalCode,
      countryName: params?.countryName,
      price: params?.price,
      start_date: params?.start_date,
      end_date: params?.end_date,
      meal: params?.meal,
      units: params?.units,
      nights: parseInt(params?.nights),
      OriginalCurrency: params?.OriginalCurrency,
      OriginalPrice: params?.OriginalPrice,
      currency: params?.currency,
      EquivalentPriceUSD: params?.EquivalentPriceUSD,
      supplier: params?.supplier,
      codeContext: params?.codeContext,
      rooms: JSON.parse(params?.reservedRooms),
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/book`,
      data,
    })
      .then((res) => {
        dispatch(setConfirmHotelBookingModal(false));
        if (res) {
          if (res.status === 200) {
            enqueueSnackbar("Booking successfull", { variant: "success" });
            if (params?.payOption === "now") {
              let parameters = {
                ...params,
                bookingId: res?.data?.data?.bookedHotel?._id,
                bookId: res?.data?.data?.bookedHotel?.bookingId,
                bookingDate: res?.data?.data?.bookedHotel?.createdAt,
                price: res?.data?.data?.bookedHotel?.grandTotal,
              };
              navigate(`/accommodation/payment?${URLencode(parameters)}`);
            } else {
              navigate("/order");
            }
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wronng. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        dispatch(setConfirmHotelBookingModal(false));
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });
    setLoading && setLoading(false);
  };

  function getBaseURL() {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    const baseURL = `${protocol}//${hostname}${port ? ":" + port : ""}`;
    return baseURL;
  }

  const initializePayment = async (setLoading) => {
    const params = URLdecode();

    let parameters = {
      bookingId: params?.bookingId,
      bookId: params?.bookId,
      bookingDate: params?.bookingDate,
    };

    const data = {
      hotelBookingId: params?.bookingId,
      paymentMode: "Card",
      callbackUrl: `${getBaseURL()}/accommodation/confirmation?${URLencode(
        parameters
      )}`,
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/payment/v1/payment/hotel`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            if (res?.data?.data?.data?.authorization_url) {
              window.location.href = res?.data?.data?.data?.authorization_url;
            }
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wronng. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const getHotelBookings = async (setLoading) => {
    setLoading && setLoading(true);

    const response = await fetchServer({
      method: "GET",
      url: `/product/v1/hotel`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res.data.data;
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    setLoading && setLoading(false);
    return response || [];
  };

  const getParameters = (booking) => {
    let guests = booking?.holdBookingRes?.guests;
    let rooms = booking?.holdBookingRes?.bookedRooms;
    rooms = rooms?.map((room, index) => {
      return {
        name: `Room ${room?.typeCode ? room?.typeCode : ""} : ${room?.type}`,
        guests: [guests[index]],
        ...room,
      };
    });

    let travelerMix = "";
    let childrenCount = 0;
    let childrenAge = "";
    let adultsCount = 0;
    let roomCount = rooms.length;

    guests.map((guest) => {
      if (guest.type === "Adult") {
        adultsCount += 1;
      } else if (guest.type === "Child") {
        childrenCount += 1;
        childrenAge += `${childrenAge ? ", " : ""} ${calculateAge(
          guest?.birthdate
        )} years old `;
      }
    });

    if (childrenCount > 0) {
      travelerMix += `${childrenCount} child${childrenCount > 1 ? "ren" : ""}${
        childrenAge && "(" + childrenAge + ")"
      }`;
    }

    if (adultsCount > 0) {
      if (travelerMix) {
        travelerMix += ", ";
      }
      travelerMix += `${adultsCount} adult${adultsCount > 1 ? "s" : ""}`;
    }

    if (roomCount > 0) {
      if (travelerMix) {
        travelerMix += " and ";
      }
      travelerMix += `${roomCount} room${roomCount > 1 ? "s" : ""}`;
    }

    let parameters = {
      name: booking?.holdBookingRes?.name,
      supplier: booking?.holdBookingRes?.supplier,
      hotelSupplier: booking?.holdBookingRes?.hotelSupplier,
      address: booking?.holdBookingRes?.details?.address?.content,
      phone: JSON.stringify(booking?.holdBookingRes?.details?.phone),
      start_date: booking?.holdBookingRes?.start_date,
      end_date: booking?.holdBookingRes?.end_date,
      nights: booking?.holdBookingRes?.nights,
      room: booking?.holdBookingRes?.bookedRooms?.length,
      reservedRooms: JSON.stringify(rooms),
      bookingDate: booking?.createdAt,
      bookingId: booking?._id,
      bookId: booking?.holdBookingRes?.control_number,
      status: booking?.status,
      vat: booking?.holdBookingRes?.vatNumber,
      images: JSON.stringify(booking?.holdBookingRes?.details?.image),
      accommodationType: booking?.holdBookingRes?.details?.accomodationType,
      categoryType: booking?.holdBookingRes?.details?.categoryDescription,
      travelerMix,
    };

    return parameters;
  };

  const getBooking = async () => {
    const params = URLdecode();

    dispatch(setLoadingModal(true));

    await fetchServer({
      method: "GET",
      url: `/product/v1/hotel/${params?.bookingId}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;
            let parameters = getParameters(booking);
            let url = `/accommodation/confirmation?${URLencode(parameters)}`;
            window.location.replace(url);
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wronng. please try again later.",
              {
                variant: "error",
              }
            );
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
      });
    dispatch(setLoadingModal(false));
  };

  const cancelAndRefund = async () => {
    const params = URLdecode();

    dispatch(setLoadingModal(true));

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/cancel-confirmed/${params?.bookingId}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;
            let parameters = getParameters(booking);
            let url = `/accommodation/confirmation?${URLencode(parameters)}`;
            window.location.replace(url);
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wronng. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wronng. please try again later.", {
          variant: "error",
        });
      });
    dispatch(setLoadingModal(false));
  };

  return {
    getHotels,
    getHotelAvailability,
    checkRoom,
    travellersString,
    bookRooms,
    initializePayment,
    getHotelBookings,
    getBooking,
    cancelAndRefund,
    checkRooms,
    checkRooms,
    recheckRooms,
  };
}
